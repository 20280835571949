import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJournalJumbotron,
  CBreadCrumb,
  CJournalLead,
  CJournalText,
  CBtnList,
  LWrap,
  CProfileMedia,
  CQuoteBox,
  CSingleImg,
  CSingleImg02,
  CChapterHeading,
  CTileImg,
  CLabelTextList,
  AssetImage,
} from "../../../components/_index";
import '../../../assets/_sass/page/journal/journal11.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "pickup",
              path: "/pickup/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <CJournalJumbotron
        data={{
          label: "横浜ロイヤルパークホテル",
          date: "2024年09月12日",
          title: (
            <>
              Last 200 days 
              <br className="u_sp"/>
              〜わたしたちの 横浜ロイヤルパークホテル〜
            </>
          ),
          text: "横浜ロイヤルパークホテル",
          img: {
            src: "/assets/images/journal/article13/kv.png",
          },
          imgSp: {
            src: "/assets/images/journal/article13/kv__sp.png",
          },
        }}
      />
      <div className="u_pb60">
        <LWrap size="small">
          <CJournalLead
            lead={
              <>
                <p>
                横浜ロイヤルパークホテルは、2025年3月末をもって営業を一時休止することが発表されている。リニューアルオープンは2028年以降。休業まで残り200日（2024年9月12日時点）という節目に、ホテルのスタッフたちは今何を想い、そして何を語るのだろう。3人のスタッフの話から、横浜ロイヤルパークホテルのこれまでとこれからを見つめてみた。
                </p>
              </>
            }
          />
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "01",
            },
            title: (
              <>
                <span>Prologue</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CSingleImg
              img={{
                src: "/assets/images/journal/article13/img_chapter01_01.png",
                alt: "",
              }}
            />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「あなたの思い出のホテルとはどんなホテルですか？」
                  </p>
                  <p>
                    そんな質問を投げかけられたら、どうだろう。
                  </p>
                  <p>
                    家族と誕生日を祝った、あのレストランの一皿。<br />
                    特別なプロポーズのために少しだけ背伸びをして予約した、あの客室。<br />
                    ホテルの部屋から見た、あの美しい夜景。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter01_02.png",
              alt: "",
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter01_03.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  どれも思い出されるのは、大切な時間を過ごしたワンシーンではないだろうか。
                  </p>
                  <p>
                  ホテルとは日常の中に、小さな非日常を与えてくれる特別な場所だ。<br />
                  いつもと変わらない日々の中にありながら、そこで過ごした時間、出会った人、体験した味が忘れられない思い出となる
                  </p>
                  <p>
                  そんな思い出を呼び起こすために、横浜ロイヤルパークホテルへ足を運んでみた。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter01_04.png",
              alt: "",
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter01_05.png",
              alt: "",
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "02",
            },
            title: (
              <>
                <span className="u_pr0_pc">街とともに、</span>
                <span>ホテルも人も進化する</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  「入社は1993年4月。ちょうど横浜ロイヤルパークホテルが開業した年に新入社員として入りました。なんだか縁を感じますね」  
                  </p> 
                  <p>
                  気さくな雰囲気で場の空気を一瞬にして和ませてくれるのは、横浜ロイヤルパークホテルで宿泊部長を務める友田貴久氏だ。  
                  </p> 
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter02_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  開業当初を知るスタッフとして、ホテルの変遷を誰よりも近くで見守ってきた。<br />
                  何もない客室に次々と家具や装飾品が運び込まれ、みるみるうちに“ホテル”が出来上がっていく様を目にしながら、何とも言えない高揚感が湧いてきた日を思い出す。
                  </p>
                  <p>
                  「横浜ロイヤルパークホテル開業当時のみなとみらいは、開発の真っ只中でした。ホテルや商業施設もほとんどなく、あたりは空き地ばかり」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter02_02.png",
              alt: "",
            }}
            caption={
              <>
                約30年前のみなとみらいの様子
              </>
              }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  そんな中での開業とあり、横浜ランドマークタワーのオープンは大きな注目を集めた。華やかなセレモニーが催され、多くの関係者やメディア、見学者がホテルにも訪れた。
                  </p>
                  <p>
                  「最初に配属されたのは客室サービス課で、お客様をお迎えするため客室の準備をする仕事でした。開業当日も表ではオープニングセレモニーが行われていましたが、わたしたちは裏でてんやわんや。常に走り回っていた記憶がありますね（笑）」
                  </p>
                  <p>
                  30年という時が流れ、一大観光地として発展を遂げてきたみなとみらい。人気観光地となった今も、大型アリーナの開業や新たなホテル、商業施設のオープンなど進化を続けている。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter02_03.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  「街と、もてなす。」
                  </p>
                  <p>
                  このコンセプトの通り、横浜ロイヤルパークホテルもまた、みなとみらいの街の変化に合わせて変わってきた。
                  </p>
                  <p>
                  「わたしたちが若い頃は、ホテルに気軽に泊まるという体験はありませんでしたが、今はカジュアルに幅広い世代の方に泊まっていただける。それはSNSやネット予約サービスの影響が大きいと思います。お客様のSNSの投稿を見て、『こんなところも魅力になるのか』と逆に気づかせてもらい、新しい打ち出しや施策のヒントを得ることも多いですよ」
                  </p>
                  <p>
                  “変化は良いこと”。友田氏はこの言葉を何度も口にする。<br />
                  一時休業は寂しさもあるが、横浜ロイヤルパークホテルが新しく生まれ変わる良いタイミング。再開後のホテルの変化も楽しみだと、常に前向きな姿勢だ。<br />
                  しかしただ一つだけ、休業にあたり心惜しく思っているものがあるという。<br />
                  それは長年ともに働いてきたスタッフとの別れ。
                  </p>
                  <p>
                  「寂しいですね。横浜はスタッフ同士の空気感が非常に良く、大きな国際会議やスポーツの大会、ライブイベントなどが開催される時には、各部署が連携してお客様をお迎えします。一人の力や一つの部署のパワーだけで対応できることではなく、チーム力が試されるので、普段からのフラットなコミュニケーションがものをいうのだと思います」
                  </p>
                  </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter02_04.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                 <p>
                  ホテルは突き詰めると“人”だと友田氏は語る。<br />
                  サービスや客室の清潔さ、提供される料理の味。その全てにその人自身が宿る。だからこそ、人が磨かれ、人によって作り上げられるものだというのだ。
                 </p>
                 <p>
                 「一時休業が発表されて、お客様や近隣企業の方から温かいお声をたくさんいただきました。寂しい、再開後はどうなるのか、休業している間スタッフはどうするのか、と。わたしたちスタッフを心配していただくお声まで。そんな言葉をいただくと心が温まると同時に、お客様もそして何よりわたしたちスタッフも本当に、横浜ロイヤルパークホテルを愛しているのだと、改めて再認識することができます。残り200日を大切にして、お客様をお迎えしなければと身が引き締まる想いです」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter02_05.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  一日一日を、そして一組一組のお客様を大切にすること。<br />
                  そこからまた新しい横浜ロイヤルパークホテルが生まれるはずだから。
                  </p>
                  <p>
                  「人生の半分以上を、ここで過ごしてきました。わたしにとっては、横浜ロイヤルパークホテルは日常の一部なのですが、お客様にとっては“非日常”です。だからこそ、サービスをするときは今でもほんの少し良い緊張感があります。この気持ちはずっと持ち続けていたいです」
                  </p>
                  <p>
                  そう笑顔で語る友田氏の表情には、未来へ向かう明るい気持ちがうかがえた。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter02_06.png",
              alt: "",
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "03",
            },
            title: (
              <>
                <span className="u_pr0_pc">人生の大切な瞬間を、</span>
                <span>創り上げるよろこび</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  華やかなホテルという場所で働く人を思い浮かべた時に、ベルスタッフやフロントスタッフの姿が思い浮かぶだろう。しかし実は目には見えない裏方としての仕事もホテルにはたくさんある。
                  </p>
                  <p>
                  そんな裏方の中でも一時休業にあたり、特別な想いを寄せるスタッフがいる。それが宿泊業務企画課の池田早紀子氏だ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter03_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  「わたし自身の転機は、現在の宿泊業務企画課に異動したことです。それまでベルスタッフなどお客様と対面する仕事をしてきたのですが、裏方に回ったことでこういった立場でもホテルを支えることができるのか、と新たな発見でした」
                  </p>
                  <p> 
                  池田氏の仕事は宿泊プランの企画が主だ。<br />
                  603室というみなとみらい随一の部屋数を誇る横浜ロイヤルパークホテルは、5つの部屋カテゴリー、さらに部屋タイプも多数あるため、幅広い滞在ニーズに合わせたプランを用意している。それに加えシーズンによる需要や観光地ならではのスポーツやイベント時の需要なども考慮して企画を行う。<br />
                  どういった宿泊プランを、どのタイミングで発信すればニーズに応えられるのか。そんなことに向き合いながら日々業務にあたっているのだ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter03_02.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  だからこそ、今回の一時休業というニュースは自身の中でも大きな意味を持つ。
                  </p>
                  <p>
                  「まさか休業するとはわたしたちスタッフも思っていなかったので、正直びっくりしました。どうなるのだろうという不安とともに、残り少ない期間でリピーターの方にも来ていただきたいですし、これを機に初めての方にも来ていただいて、横浜ロイヤルパークホテルに宿泊してほしいと今回のクロージングプランを作りました」
                  </p>
                  <p>
                  「生涯顧客」という言葉があるとおり、ホテルでの一度の体験が人生において大事なきっかけとなり、その後も長く関係が続くこともある。だからこそ、その“初めの一歩”がとても重要だ。その初めてを特別なものにしてもらいたいと、池田氏はクロージングプランに願いを込めた。
                  </p>
                  <p>
                  「65-66F のスカイリゾートフロア「ザ・クラブ」は、横浜ロイヤルパークホテルの中でも最上級のランクです。そのフロアでの宿泊をいつもよりリーズナブルな価格でご提供することで、非日常でちょっとリッチな体験、そして思い出に残る時間を過ごしていただきたいなと」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter03_03.png",
              alt: "",
            }}
            caption={
              <>
                「感謝」の気持ちをクロージングのロゴにも込めて／60-64Fスカイリゾートフロア「アトリエ」の客室。窓際のカウンター席から絶景をお楽しみいただけたらと太鼓判を押す。
              </>
              }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  横浜ロイヤルパークホテルはフルサービス型のホテルであり、かつ高層階の眺望はほかにはない魅力として、記念日など特別な日に選ばれることも多い。たとえば、プロポーズや結婚式、その後の記念日などにも。<br />
                  何を隠そう池田氏自身も、横浜ロイヤルパークホテルという場所で人生の節目を迎えてきた一人だ。初めて社会人として人生をスタートさせた場所で、自身の結婚式を挙げ、出産を経てライフステージが変わった現在も勤め続けている。
                  </p>
                  <p>
                  「わたしにとってもこのホテルは本当にかけがえのない存在です。産休・育休を取ったタイミングで辞めるという考えがなかったわけではないのですが、それでもまた戻りたい、仕事を続けたいと思ったのはやっぱりこのホテルが、人が好きだからだと思うのです」
                  </p>
                  <p>
                  池田氏の言葉から、ホテルに対する愛情を感じた。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter03_04.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  みなとみらいのシンボルとも言える横浜ロイヤルパークホテルに一度は泊まってみたいものだが、泊まらずともその魅力を体験できるものはあるのだろうか。
                  </p>
                  <p>
                  「49F ランドマークスパは、宿泊の方以外でもご利用可能です。全長20mのプールや、ジム、サウナ、スパが利用できて、天気が良い日にはプールから富士山が見えることもありますよ。その他にもレストランやバーなど飲食施設も多数あります」
                  </p>
                  <p>
                  一番のおすすめは？と聞くと。
                  </p>
                  <p>
                  「デリカ＆ラウンジ「コフレ」のケーキです。子どもの誕生日などイベントの時は、決まってコフレのケーキを買います。甘すぎないホテルオリジナルのクリームが本当に美味しくて。子どもからも、何かあるとコフレのケーキがいい！とリクエストがあることも」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter03_05.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  泊まっても、泊まらなくても。その魅力を味わうことができる、それもまた一つ横浜ロイヤルパークホテルの魅力だ。
                  </p>
                  <p>
                  残り少ない時間で、このホテルが一人でも多くの人の“大切な場所”になれたらと、池田氏は最後に笑顔を見せた。       
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter03_06.png",
              alt: "",
            }}
          />
        </LWrap>
        </section>

        <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "04",
            },
            title: (
              <>
                <span className="u_pr0_pc">お客様の笑顔のために、</span>
                <span>一番のおもてなしを</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  横浜ロイヤルパークホテルの66F。エレベーターフロアを抜けると突き当たりにコンシェルジュデスクが現れ、吹き抜けの空間が広がる。ここは、65-66F スカイリゾートフロア「ザ・クラブ」の宿泊者のみが利用することができる「クラブラウンジ」。<br />
                  そこで働くのが、コンシェルジュを務める春日井純子氏だ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter04_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  「わたし、どちらかというと距離が近いタイプなんです。だからいらっしゃったお客様とはできるだけお話したいですし、親しみを持ってもらいたいと思っています。入社してから23年ずっとお客様と対面する仕事をしてきて、この仕事は自分に合っているのだと思います」
                  </p>
                  <p> 
                  コンシェルジュという名に相応しく、笑顔を絶やさない春日井氏の醸し出す雰囲気は、自然と心を開いてしまうような心地良さや程よい距離感がある。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter04_02.png",
              alt: "",
            }}
            caption={
              <>
                ゆったりとしたクラブラウンジ。吹き抜けの高い天井と大きな窓から見える風景に開放感を感じる。
              </>
              }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  春日井氏の仕事は、65-66Fのクラブフロアに宿泊するお客様のチェックインやチェックアウトなどの業務のほか、予約内容の管理や準備、そしてクラブラウンジでのホールサービスなど多岐にわたる。
                  </p>
                  <p>
                  「クラブフロアに宿泊のお客様は、エレベーターで直接コンシェルジュデスクにお越しいただいて座りながらチェックインなどの手続きができます。朝7時から夜の21時まで開いているクラブラウンジを利用していただき、ここから横浜の景色をゆったり楽しんでいただくことができます」
                  </p>
                  <p>
                  モーニングタイムには、シェフが目の前で仕上げるオムレツや神奈川県産の野菜を使ったサラダ、焼き立てのパンなどをブッフェスタイルで楽しむ朝食を。ティータイムにはコンシェルジュおすすめのお菓子をコーヒーや紅茶などのドリンクとともに。アフタヌーンには、ホテルオリジナルのサンドウィッチやミニケーキ、イブニングタイムにはオードブルやチーズをつまみながらアルコールを楽しむこともでき、一日中過ごすことができる。
                  </p>
                  <p>
                  ラウンジのフードにもコンシェルジュのおもてなしが。シーズンごとにテーマを決め、スタッフ自らお菓子をセレクトしているのだ。
                  </p>
                  <p>
                  「横浜らしいものを意識してお土産の定番品などを揃えています。地元のお店や今横浜で流行っているものは、常日頃からチェックしていますね。プライベートで出かけても、これは提供できるのではなんて視点で見てしまうことも。職業病かもしれません（笑）」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter04_03.png",
              alt: "",
            }}
            caption={
              <>
                「コフレ」のマシュマロを活用したクッキーや耳まで使った自家製パンのサンドウィッチなどSDGsにも取り組む。<br className="u_pc"/>
                地産地消として神奈川県産野菜のサラダや地元の豆腐屋さんの豆乳を使ったスープも好評だ。
              </>
              }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  コンシェルジュの仕事は、経験していくにつれ深みにハマっていくおもしろさがあるのだと春日井氏は語る。何年か腰を据えて働くことで、仕事のこともお客様のことも深く理解することができる。そして当然、お客様との大切なエピソードも増えていく。
                  </p>
                  <p>
                  「もう何十年もホテルに通っていただいているお客様がいらっしゃいます。以前はお母様と一緒にご宿泊されていたのですが、数年前にお母様が亡くなってしまって…。それからしばらくして、ご自身の誕生日に宿泊の予約を入れていただきました。お母様が亡くなってから初めての滞在でしたので、何かできないかと話し合って、お誕生日のお祝いをさせてもらうことにしました」
                  </p>
                  <p>
                  スタッフ手製のバースデーカード、そしてそのお客様をイメージした特注のケーキも用意。<br />
                  当日はサプライズで客室にお祝いをセッティングし、お客様を囲み、スタッフみんなでバースデーソングを歌った。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter04_04.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  「とにかく寂しい想いをしてほしくないと思い、お出ししたケーキをスタッフも一緒にみんなで食べましょうと提案させていただきました。泣きながら『ありがとう』とおっしゃってくださったお客様に笑顔が見られて、ホッとしました」
                  </p>
                  <p>
                  それ以降、そのお客様は自身の誕生日には毎年予約を入れてくれているそうだ。
                  </p>
                  <p>
                  「わたしたちの仕事は、お客様がどういった想いでホテルへいらっしゃるのかを理解することだと思います。リピーターの方はもちろん、初めてお越しになられる方も、もしかしたら特別な日のために予約をしてくれたかもしれない、と。一つの“予約”の背景にはどんな想いがあるのか、それを読み解いていくことがサービスにもつながってくると思います」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter04_05.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  一時休業まで200日、どんな過ごし方がおすすめなのか聞いてみると、「クラブフロアに宿泊を、そしてできたら2泊以上してほしい」と春日井氏は付け加える。
                  </p>
                  <p>
                  「一日って案外あっという間なんです。さらにもう1泊していただければ、ランドマークスパに足を運んでみたり、ティータイムを優雅に楽しんだり、ラウンジからの景色をぼんやりと眺めたり。少し余裕を持った楽しみ方ができると思います」       
                  </p>
                  <p>
                  このラウンジにまた再び、人と人の交流が生まれる日を待ち望んで。再開後はお客様に「おかえりなさい」と伝えたいと春日井氏はいう。<br />
                  休業までの間に、特別な体験を味わいにクラブフロアに足を運んでみるのもいいだろう。<br />
                  できれば2泊して、横浜の移り行く風景を楽しみながら。       
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter04_06.png",
              alt: "",
            }}
          />
        </LWrap>
        </section>

        <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "05",
            },
            title: (
              <>
                <span className="u_pr0_pc">残り200日、</span>
                <span>思い出をまたここから</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter05_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  お気に入りのホテルができるということは、<br />
                  自分にとっての“もうひとつの我が家” を作るような感覚だ。
                  </p>
                  <p> 
                  そこに帰れば「おかえりなさい」と迎えてくれる人がいる。<br />
                  そして、そこで過ごす時間は自分らしさを取り戻すための時間でもある。<br />
                  泊まっていない時間ですら、帰れる場所があるという安心感をもたらしてくれる。
                  </p>
                  <p> 
                  そんな場所、思い入れのあるホテルに、横浜ロイヤルパークホテルがなれたなら。
                  </p>
                  <p> 
                  「もう一度 思い出を、このホテルで」
                  </p>
                  <p> 
                  再開後はどんな姿で、おもてなしで、わたしたちを迎えてくれるだろう。<br />
                  期待に胸を膨らませつつ、残りわずかな期間に横浜ロイヤルパークホテルへ足を運んでみてほしい。きっとあたたかなおもてなしがあなたを待っているだろう。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article13/img_chapter05_02.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>本記事・画像は 2024 年 9 月時点のものです。</li>
                  </ul>
                </>
              ),
            }}
          />

          <p className="u_tac u_fs20 u_mbSmall">横浜ロイヤルパークホテル <br className="u_sp"/>おすすめプラン</p>
          <CQuoteBox>
            <h3 className="c_headingLv3 u_tac">
            クロージング宿泊プラン
            </h3>
            <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "プラン詳細",
                color: "bgBlack",
                link: {
                  href: "https://www.yrph.com/information/closingstay/",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
          </CQuoteBox>

          <p className="u_fwb u_tac u_mt80">横浜ロイヤルパークホテル</p>
          <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "ホテルサイト",
                color: "bgBlack",
                link: {
                  href: "https://www.yrph.com/",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
          </LWrap>
        </section>

      <div className="l_sect02 u_bgGray">
        <LWrap>
          <CBtnList
            data={[
              {
                label: "ロイヤルパークホテルズJournal",
                link: {
                  href: "/pickup/#journal",
                },
                iconBefore: true,
                icon: "arrowReverse",
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
